<template>
  <div
    class="flex flex-col items-center md:items-start md:justify-center md:flex-row"
  >
    <div class="p-4 flex flex-col gap-6 max-w-xl">
      <header class="flex flex-col gap-2">
        <h1 class="text-white font-medium text-2xl">
          Gerador de headlines gratuito
        </h1>
        <p class="text-white font-medium text-lg">
          Utilize o formulário abaixo para gerar ideias de copys para headline.
        </p>
      </header>
      <form
        class="shadow-md flex flex-col gap-4"
        @submit.prevent="generateHeadline"
      >
        <div class="">
          <label
            class="block text-white/90 text-base font-bold mb-2"
            for="tipoOferta"
            >Tipo de Oferta:</label
          >
          <select
            class="backdrop-blur-lg bg-white/5 shadow appearance-none border-2 border-white/10 rounded-2xl w-full py-4 px-4 text-white/80 font-bold leading-tight focus:outline-none focus:shadow-outline"
            id="tipoOferta"
            name="tipoOferta"
            v-model="selectedTipo"
          >
            <option value="" disabled selected>Escolha o tipo de oferta</option>
            <option value="produto_fisico">Produto Físico</option>
            <option value="mentoria">Mentoria</option>
            <option value="ebook">Ebook</option>
            <option value="curso">Curso</option>
            <option value="suplemento">Suplemento</option>
          </select>
        </div>

        <div class="">
          <label
            class="block text-white/90 text-base font-bold mb-2"
            for="nichoProduto"
            >Nicho do Produto:</label
          >
          <select
            class="backdrop-blur-lg bg-white/5 shadow appearance-none border-2 border-white/10 rounded-2xl w-full py-4 px-4 text-white/80 font-bold leading-tight focus:outline-none focus:shadow-outline"
            id="nichoProduto"
            name="nichoProduto"
            v-model="selectedNicho"
          >
            <option value="" disabled selected>
              Escolha o nicho do produto
            </option>
            <option value="emagrecimento">Emagrecimento</option>
            <option value="renda_extra">Renda Extra</option>
            <option value="bem_estar">Bem-estar</option>
            <option value="cosmeticos">Cosméticos</option>
            <option value="saude_mental">Saúde Mental</option>
            <option value="fitness">Fitness</option>
            <option value="culinaria">Culinária e Receitas</option>
            <option value="relacionamentos">Relacionamentos</option>
            <option value="moda">Moda</option>
            <option value="beleza">Beleza</option>
            <option value="financas_pessoais">Finanças Pessoais</option>
            <option value="investimentos">Investimentos</option>
            <option value="tecnologia">Tecnologia</option>
            <option value="automobilismo">Automobilismo</option>
            <option value="decoracao">Decoração</option>
            <option value="jardinagem">Jardinagem</option>
            <option value="viagens">Viagens</option>
            <option value="esportes">Esportes</option>
            <option value="musica">Música</option>
            <option value="fotografia">Fotografia</option>
            <option value="arte">Arte</option>
            <option value="cinema">Cinema</option>
            <option value="literatura">Literatura</option>
            <option value="animais_de_estimacao">Animais de Estimação</option>
            <option value="desenvolvimento_pessoal">
              Desenvolvimento Pessoal
            </option>
            <option value="empreendedorismo">Empreendedorismo</option>
            <option value="sustentabilidade">Sustentabilidade</option>
            <option value="idiomas">Aprendizado de Idiomas</option>
            <option value="historia">História</option>
            <option value="astronomia">Astronomia</option>
            <option value="ciencia">Ciência</option>
            <option value="filosofia">Filosofia</option>
          </select>
        </div>

        <div class="form-group">
          <label
            class="block text-white/90 text-base font-bold mb-2"
            for="nomeProduto"
            >Nome do Produto:</label
          >
          <input
            class="backdrop-blur-lg bg-white/5 shadow appearance-none border-2 border-white/10 rounded-2xl w-full py-4 px-4 text-white/80 font-bold leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            id="nomeProduto"
            v-model="nomeProduto"
            name="nomeProduto"
            placeholder="Creme anti rugas"
            required
          />
        </div>

        <div class="form-group">
          <label
            class="block text-white/90 text-base font-bold mb-2"
            for="promessaPrincipal"
            >Promessa Principal (Opcional):</label
          >
          <input
            class="backdrop-blur-lg bg-white/5 shadow appearance-none border-2 border-white/10 rounded-2xl w-full py-4 px-4 text-white/80 font-bold leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            id="promessaPrincipal"
            v-model="promessaPrincipal"
            name="promessaPrincipal"
            placeholder="Eliminar as rugas"
          />
        </div>
        <button
          type="submit"
          class="bg-purple-500 text-white font-bold text-lg px-4 py-4 rounded-2xl mt-2"
        >
          Gerar Headline
        </button>
      </form>

      <div v-show="showHeadlines" class="mt-4 mb-8">
        <h2 class="text-2xl font-bold mb-4 text-white">Suas Headlines:</h2>
        <!-- Lista de Headlines -->
        <div class="space-y-4">
          <div
            v-for="headline in filteredHeadlines"
            :key="headline.id"
            class="backdrop-blur-lg shadow appearance-none border-2 border-white/40 rounded-2xl w-full py-4 px-4 leading-tight focus:outline-none focus:shadow-outline group hover:bg-purple-600/90 hover:border-white/10 cursor-pointer"
          >
            <span class="mr-4 text-base text-white/80 font-bold">{{
              formatHeadline(headline.headline)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="h-[calc(100vh_-_340px)] sticky top-8">
      <a href="https://voltk.com.br?src=gerador&utm_source=gerador">
        <img src="../assets/gerador.png" alt="" style="width: 100%; border-radius: 10px;"/>
      </a>
    </div>

  </div>
</template>

<script>
  import templates from "../../templates.json";
  export default {
    data() {
      return {
        selectedTipo: "",
        selectedNicho: "",
        nomeProduto: "",
        promessaPrincipal: "",
        headlines: templates,
        showHeadlines: false,
      };
    },
    computed: {
      filteredHeadlines() {
        return this.headlines.filter(
          (h) =>
            h.tipo.includes(this.selectedTipo) &&
            h.nicho.includes(this.selectedNicho)
        );
      },
    },
    methods: {
      formatHeadline(template) {
        return template
          .replace("{nomeProduto}", this.nomeProduto)
          .replace("{promessaPrincipal}", this.promessaPrincipal)
          .replace("{nichoProduto}", this.selectedNicho)
          .replace("{tipoProduto}", this.selectedTipo);
      },
      generateHeadline() {
        this.showHeadlines = true;
      },

      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(
          () => {
            // Mostra uma mensagem de sucesso ou algo assim, se desejar.
          },
          (err) => {
            console.error("Erro ao copiar o texto: ", err);
          }
        );
      },
    },
  };
</script>

<style scoped>
  /* Estilos específicos do componente (se necessário) */
</style>
