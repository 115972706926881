<template>
  <div class="max-h-full flex flex-col items-center gap-8 py-14">
    <img alt="Vue logo" src="./assets/logo.svg" class="h-20" />
    <HeadlineGenerator />
  </div>
</template>

<script>
  import HeadlineGenerator from "./components/HeadlineGenerator.vue";

  export default { 
    name: "App",
    components: {
      HeadlineGenerator,
    },
  };
</script>

<style>
  #app {
    background-color: #211940;
    background-image: radial-gradient(
        circle at top right,
        rgba(170, 1, 254, 0.4) 0%,
        rgba(170, 1, 254, 0) 70%
      ),
      radial-gradient(
        circle at bottom left,
        rgba(85, 42, 236, 0.4) 0%,
        rgba(85, 42, 236, 0) 70%
      );
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    background-size: 500px 500px;

    overflow-y: auto;

    @apply flex flex-col items-center justify-center;
  }
</style>
